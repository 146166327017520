import * as React from 'react';
import { Link, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

// Components
import Layout from '../../components/layout/Layout';
import RealisationCard from '../../components/card/Realisation';
import PaginationBlock from '../../components/block/Pagination';

// Realisations
const RealisationsPage = ({ pageContext, data }) => {
  const { totalCount } = data.realisations
  const pageTitle = `Réalisations - ${totalCount} réalisation${totalCount === 1 ? "" : "s"}`

  const headerBackgroundImageRandom = Math.floor(Math.random() * data.realisations.nodes.length);
  const headerBackgroundImage = data.realisations.nodes[headerBackgroundImageRandom].frontmatter.image.cover ? data.realisations.nodes[headerBackgroundImageRandom].frontmatter.image.cover.childImageSharp.fluid : null;
  const headerBackgroundImageStack = [
    headerBackgroundImage,
    `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))`,
  ].reverse()

  return (
    <Layout
      pageTitle={`${pageTitle} | ${data.site.siteMetadata.title} - L'entreprise spécialiste du bâtiment à Roanne`}
      pageDescription={`Découvrez toutes les réalisations de ${data.site.siteMetadata.title} à Roanne et dans ses environs: plâtrerie peinture, carrelage et faïence, sols stratifiés, papier peints, isolation thermique par l'extérieur ou l'intérieur.`}
      pageKeywords="pcc bâtiment, réalisations, réalisation, roanne, riorges, plâtrerie, peinture, carrelage, faience, sols stratifiés, papier peints, isolation"
      headerClassName="header header-layout-type-header-2rows"
    >
      <BackgroundImage
        Tag="section"
        className="page-title divider layer-overlay overlay-dark-8 section-typo-light bg-img-center"
        fluid={headerBackgroundImageStack}
      >
        <div className="container pt-90 pb-90">
          <div className="section-content">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2 className="title text-white">Nos réalisations</h2>
                <nav role="navigation" className="breadcrumb-trail breadcrumbs">
                  <div className="breadcrumbs">
                    <span className="trail-item trail-begin">
                      <Link to={`/`}>Accueil</Link>
                    </span>
                    <span><i className="bi bi-chevron-right"></i></span>
                    <span className="trail-item trail-end active">Réalisations</span>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      {data.realisations && data.realisations.nodes.length ?
        <section id="realisations">
          <div className="container">
            <div className="section-content">
              <div className="tm-sc tm-sc-projects projects-style8-current-theme">
                <div className="row">
                  {data.realisations.nodes.map(realisation => (
                    <RealisationCard key={realisation.id} data={realisation} cardStyle="1" cardClassName="col-md-6 col-lg-6 col-xl-4 mb-30" />
                  ))}
                </div>
              </div>
            </div>
            <div>
              <PaginationBlock
                path={`realisations`}
                currentPage={pageContext.currentPage}
                numPages={pageContext.numPages}
              />
            </div>
          </div>
        </section>
      : null}
    </Layout>
  )
}

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {  
    site: site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
        image {
          alt
        }
      }
    }
    realisations: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/realisations/" }, 
        frontmatter: { active: { eq: true } }
      },
      sort: {fields: frontmatter___date, order: DESC}
      limit: $limit
      skip: $skip
    ) {
      nodes {
        frontmatter {
          title
          description
          type
          location
          surface
          budget
          duration 
          image {
            cover {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            card {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            credit {
              text
              link
            }
          }
          home
          active
          date(formatString: "D MMMM YYYY", locale: "fr")
        }
        id
        slug
      }
      totalCount
    }
  }
`

export default RealisationsPage